import { useEffect, useState } from "react";
import { Notyf } from "notyf";
import { Typeahead } from "react-bootstrap-typeahead";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "notyf/notyf.min.css";
import "./App.css";
import {
  AiOutlineCloseCircle,
  AiOutlineCopy,
  AiOutlineDeliveredProcedure,
  AiOutlineFileAdd,
  AiOutlineFileExcel,
  AiOutlineSearch,
  AiOutlineUndo,
  AiOutlineUser,
} from "react-icons/ai";
const notyf = new Notyf({});

//https://assets6.lottiefiles.com/packages/lf20_x17ybolp.json

export default function App() {
  const [email, setEmail] = useState([]);
  const [brutText, setBrutText] = useState("");
  const [currentList, setCurrentList] = useState("");
  const [dorks, setDorks] = useState({
    mails: [],
    keywords: [],
    citys: [],
    sites: [],
    sector: [],
  });
  const [show, setShow] = useState(false);
  const [showTuto, setShowTuto] = useState(false);

  const handleClose = () => setShow(false);
  const handleCloseTuto = () => setShowTuto(false);
  const handleShow = () => setShow(true);
  const handleShowTuto = () => setShowTuto(true);
  const mailsSearch = [
    { value: "@gmail.com", label: "Gmail" },
    { value: "@yahoo.fr", label: "Yahoo" },
    { value: "@outlook.com", label: "Outlook" },
    { value: "@orange.fr", label: "Orange" },
    { value: "@free.fr", label: "Free" },
    { value: "@laposte.net", label: "Laposte" },
  ];

  const sitesSearch = [
    { value: "site:linkedin.com/in/", label: "Linkedin" },
    { value: "site:instagram.com", label: "Instagram" },
    { value: "site:facebook.com", label: "Facebook" },
    { value: "site:pagesjaunes.fr", label: "Pagesjaunes" },
  ];
  const [listes, setListes] = useState(
    JSON.parse(
      localStorage.getItem("listes") ? localStorage.getItem("listes") : "[]"
    )
  );
  const regex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g;
  const sitesDorkQuery =
    dorks.sites.length <= 1
      ? `${dorks.sites.map(
          (d, i) => d.value + (i !== dorks.sites.length - 1 ? " OR " : "")
        )}`
      : `(${dorks.sites.map(
          (d, i) => d.value + (i !== dorks.sites.length - 1 ? " OR " : "")
        )})`;
  const keywordsDorKquery =
    dorks.keywords.length !== 0
      ? `AND (${dorks.keywords.map(
          (d, i) =>
            '"' +
            d.label +
            '"' +
            (i !== dorks.keywords.length - 1 ? " OR " : "")
        )})`
      : "";
  const citysDorKquery =
    dorks.citys.length !== 0
      ? `AND (${dorks.citys.map(
          (d, i) =>
            '"' + d.label + '"' + (i !== dorks.citys.length - 1 ? " OR " : "")
        )})`
      : "";
  const mailsDorKquery =
    dorks.mails.length !== 0
      ? `AND (${dorks.mails.map(
          (d, i) =>
            '"' + d.value + '"' + (i !== dorks.mails.length - 1 ? " OR " : "")
        )})`
      : "";
  const sectorDorkQuery =
    dorks.sector.length !== 0
      ? `AND (${dorks.sector.map(
          (d, i) =>
            '"' + d.label + '"' + (i !== dorks.sector.length - 1 ? " OR " : "")
        )})`
      : "";
  const queryDorks =
    `${sitesDorkQuery} ${keywordsDorKquery} ${sectorDorkQuery} ${citysDorKquery} ${mailsDorKquery}`.replaceAll(
      ",",
      ""
    );

  const googleRegexBase = "https://www.google.com/search?q=" + queryDorks;
  useEffect(() => {
    if (!localStorage.getItem("listes")) {
      localStorage.setItem("listes", JSON.stringify([]));
    }
    loadSavedLisr(currentList);
    if (!currentList) {
      reset();
    }
  }, [currentList]);

  useEffect(() => {
    function removeDuplicates(array) {
      return array.filter((item, index) => array.indexOf(item) === index);
    }
    if (brutText) {
      const emails = brutText.match(regex);
      if (emails) setEmail((d) => removeDuplicates([...d, ...emails]));
    }
  }, [brutText]);

  async function handleCopy() {
    await navigator.clipboard.writeText(String(email.map((data) => data)));
    notyf.success("Emails copié");
  }

  function loadSavedLisr(name) {
    if (name) {
      const listExist = listes.filter((liste) =>
        liste.nameList === name ? true : false
      );

      if (listExist[0]?.email) setEmail(listExist[0]?.email);
      if (listExist[0]?.dorks) setDorks(listExist[0]?.dorks);
    }
  }

  function saveNewList() {
    const nameList = prompt("Quelle est le nom de votre liste ?");
    if (!nameList) return alert("Nom manquant");
    const currentList = JSON.parse(localStorage.getItem("listes"));
    const listExist = currentList.filter((liste) =>
      liste.nameList === nameList ? true : false
    );
    if (!listExist.length == 0) return alert("Une liste porte déjà ce nom");
    localStorage.setItem(
      "listes",
      JSON.stringify([...currentList, { nameList, email, dorks }])
    );
    setListes((d) => [...d, { nameList, email, dorks }]);
    setEmail([]);
    setDorks({
      mails: [],
      keywords: [],
      citys: [],
      sites: [],
      sector: [],
    });
    setBrutText("");
    notyf.success("Liste créer");
  }

  const saveData = (function () {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return function (data, fileName) {
      const json = data,
        blob = new Blob([json], { type: "data:text/csv;charset=utf-8" }),
        url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    };
  })();

  function saveList() {
    const indexObj = listes.findIndex((obj) => obj.nameList == currentList);
    const listAdd = listes;
    listAdd[indexObj].email = email;
    listAdd[indexObj].dorks = dorks;
    setListes(listAdd);
    localStorage.setItem("listes", JSON.stringify(listes));
    notyf.success("Nouveaux emails sauvegarder");
  }
  function saveCsv() {
    saveData(
      "email \n" + email.join("\n"),
      (currentList ? currentList : "export") + ".csv"
    );
    notyf.success("Votre fichier à été générer avec succés");
  }
  function reset() {
    setEmail([]);
    setBrutText("");
    setCurrentList("");
    setDorks({
      mails: [],
      keywords: [],
      citys: [],
      sites: [],
      sector: [],
    });
  }
  return (
    <div className="App">
      <button
        data-tally-open="wvXdqv"
        data-tally-emoji-text="👋"
        style={{
          position: "fixed",
          bottom: "40px",
          right: "40px",
          width: "80px",
          height: "80px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          border: 0,
          borderRadius: "50%",
          background: "black",
          fontSize: 40,
        }}
        className="btn-danger"
        data-tally-emoji-animation="wave">
        🔍
      </button>
      <h1>
        Email extractor by <b>Persolive</b>
      </h1>
      <h5>
        Ciblez votre ICP, copier le texte brut de votre recherche et récuperer
        les emails exploitables{" "}
        <Button onClick={handleShowTuto} variant="danger">
          Tuto onboarding
        </Button>
      </h5>
      <Button variant="primary" onClick={handleShow}>
        <AiOutlineUser /> Ciblez votre persona
      </Button>
      <Modal show={showTuto} onHide={handleCloseTuto}>
        <Modal.Header closeButton>
          <Modal.Title>Bienvenue sur Email extractor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            style={{ border: 0 }}
            width="100%"
            height={"300"}
            src="https://www.tella.tv/video/clbvf30x901gu0fmq660ie0qg/embed"
            allowFullScreen></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseTuto}>
            <AiOutlineCloseCircle />
            Fermé
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cibler votre persona</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label className="form-check-label mt-2">Emails providers</label>
          <Typeahead
            id="custom-selections-example"
            multiple
            newSelectionPrefix="Ajoutez : "
            onChange={(e) =>
              setDorks((d) => {
                return { ...d, mails: e };
              })
            }
            options={mailsSearch}
            defaultSelected={dorks.mails}
            placeholder="Mails désirés"
          />
          <label className="form-check-label mt-2">Sites</label>
          <Typeahead
            id="custom-selections-example"
            multiple
            newSelectionPrefix="Ajoutez : "
            defaultSelected={dorks.sites}
            onChange={(e) =>
              setDorks((d) => {
                return { ...d, sites: e };
              })
            }
            options={sitesSearch}
            placeholder="Sites ressource"
          />
          <label className="form-check-label mt-2">Secteur d'activité</label>
          <Typeahead
            allowNew
            id="custom-selections-example"
            multiple
            defaultSelected={dorks.sector}
            newSelectionPrefix="Ajoutez : "
            onChange={(e) =>
              setDorks((d) => {
                return { ...d, sector: e };
              })
            }
            options={[]}
            placeholder="Sites ressource"
          />
          <label className="form-check-label mt-2">
            Titre du poste (ceo, directeur, ...)
          </label>
          <Typeahead
            allowNew
            id="custom-selections-example"
            multiple
            defaultSelected={dorks.keywords}
            newSelectionPrefix="Ajoutez : "
            onChange={(e) =>
              setDorks((d) => {
                return { ...d, keywords: e };
              })
            }
            options={[]}
            placeholder="Tapez vos mots clés (CEO, gérant)..."
          />
          <label className="form-check-label mt-2">Localité</label>
          <Typeahead
            allowNew
            id="custom-selections-example"
            multiple
            defaultSelected={dorks.citys}
            newSelectionPrefix="Ajoutez : "
            value={dorks.citys}
            onChange={(e) =>
              setDorks((d) => {
                return { ...d, citys: e };
              })
            }
            options={[]}
            placeholder="Tapez une ville, département, région et ou pays"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <AiOutlineCloseCircle />
            Fermé
          </Button>
          <Button
            variant="success"
            href={googleRegexBase}
            target="_blank"
            onClick={handleClose}>
            <AiOutlineSearch /> Lancer une recherche
          </Button>
        </Modal.Footer>
      </Modal>

      <br />
      <select
        className="form-select"
        style={{ width: 300 }}
        onChange={(e) => {
          setCurrentList(e.target.value);
        }}
        value={currentList}>
        <option default value="">
          Choisi une liste
        </option>
        {listes.map(({ nameList }, i) => (
          <option key={i} value={nameList}>
            {nameList}
          </option>
        ))}
      </select>
      <br />
      <textarea
        className="form-control"
        placeholder="Coller votre recherche google"
        style={{ width: 300 }}
        onChange={(e) => {
          setBrutText(e.target.value);
        }}
        value={brutText}></textarea>
      <br />
      <div className="btn-ctn">
        {email.length !== 0 && (
          <>
            <button
              style={{ margin: 2 }}
              className="btn btn-primary"
              onClick={() => handleCopy()}>
              <AiOutlineCopy /> Copier tout les email
            </button>
            <button
              style={{ margin: 2 }}
              className="btn btn-primary"
              onClick={() => saveNewList()}>
              <AiOutlineFileAdd /> Créer une liste
            </button>
            <button
              style={{ margin: 2 }}
              className="btn btn-primary"
              onClick={() => saveCsv()}>
              <AiOutlineFileExcel />
              export .csv
            </button>
            {currentList && (
              <>
                <button
                  style={{ margin: 2 }}
                  className="btn btn-primary"
                  onClick={() => saveList()}>
                  <AiOutlineDeliveredProcedure /> Sauvegarder les nouveaux
                  emails
                </button>
              </>
            )}
          </>
        )}
      </div>
      {email.length !== 0 && (
        <>
          <button
            style={{ margin: 2 }}
            className="btn btn-primary"
            onClick={() => reset()}>
            <AiOutlineUndo />
            Reset
          </button>
          <p
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <b>Nombre d'emails : {email.length}</b>

            <div
              style={{
                width: "90%",
                margin: 0,
                padding: "7px",
                background: "#0000003b",
                borderRadius: "20px",
              }}>
              {email.map((data, i) => (
                <span
                  key={i}
                  className="badge text-bg-primary"
                  style={{ margin: 2 }}>
                  {data}
                </span>
              ))}
            </div>
          </p>
        </>
      )}
    </div>
  );
}
